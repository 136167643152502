import { Link, useLocation } from "react-router-dom";
import collage from "../images/Footer Collage.jpg";

function Footer() {
  const location = useLocation();
  let emailAddress = "";

  if (location.pathname.includes("charters")) {
    emailAddress = "groups@atlantisadventures.com";
  } else {
    emailAddress = "ores@atlantisadventures.com";
  }

  return (
    <>
      <div
        className="container mw-100 p-0"
        style={{ backgroundColor: "#8C9BAE" }}
      >
        <div className="text-center text-light py-5">
          <h4>
            <a
              className="text-decoration-none text-white fw-bold"
              href="tel:18003810237"
            >
              QUESTIONS? 1(800)381-0237
            </a>
          </h4>
          <h4 className="mb-0">
            <a
              className="text-decoration-none text-white fw-bold text-break text-uppercase"
              href={`mailto:${emailAddress}`}
            >
              {emailAddress}
            </a>
          </h4>
        </div>
        <img src={collage} alt="footer collage" className="p-0 w-100" />
      </div>

      <div className="container footerBG mw-100 footerLinks text-light text-nowrap">
        <div className="row justify-content-center ms-2 ps-2 ms-sm-4 ps-sm-4 ms-md-0 ps-md-0">
          <div className="border-end border-light border-opacity-75 d-flex justify-content-start justify-content-md-center mb-4 mb-xl-0 p-0 me-0 me-lg-6 departCol">
            <div className="me-lg-6">
              <h5 className="fw-bold">DEPARTS FROM</h5>
              <p>
                301 Aloha Tower Drive, Pier 6<br />
                Honolulu, Hawaii 96813
              </p>
              <Link to="/parking">Ride Services and Parking Info</Link>
            </div>
          </div>
          <div className="d-flex justify-content-start justify-content-md-center mb-4 mb-xl-0 p-0 me-0 me-lg-6 majesticCol">
            <div>
              <h5 className="fw-bold text-nowrap">MAJESTIC CRUISES</h5>
              <Link to="/">Waikiki Sunset Cocktail Cruise</Link>
              <br />
              <Link to="/friday-fireworks-cocktail-cruise">
                Friday Fireworks & Cocktail Cruise
              </Link>
              <br />
              <Link to="/whalewatch">Whale Watch Cruise</Link>
              <br />
              <Link to="/boat-charters">Group Events & Charters</Link>
            </div>
          </div>

          <div className="d-flex justify-content-start justify-content-md-center mb-4 mb-md-0 p-0 me-0 me-lg-6 exploreCol">
            <div>
              <h5 className="fw-bold">EXPLORE</h5>
              <Link to="/kamaaina">Kama'aina Discounts</Link>
              <br />
              <Link to="https://atlantisadventures.com/travel-agent">
                Travel Agent Portal
              </Link>
              <br />
              <Link to="https://atlantisadventures.com">
                Atlantis Submarines
              </Link>
              <br />
              <Link to="https://atlantisadventures.com/employment">
                Employment Opportunities
              </Link>
            </div>
          </div>

          <div className="d-flex justify-content-start justify-content-md-center p-0 me-0 socialCol">
            <div>
              <h5 className="fw-bold">SOCIAL</h5>
              <Link to="https://facebook.com/atlantishawaii">Facebook</Link>
              <br />
              <Link to="https://instagram.com/atlantishawaii">Instagram</Link>
            </div>
          </div>
        </div>
        <div className="row text-center pt-5">
          <div className="col-12">
            Copyright © 2024 Atlantis Adventures LLC.
            <br />
            All rights reserved.
          </div>
        </div>
      </div>
    </>
  );
}

export default Footer;
