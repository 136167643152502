import { Helmet } from "react-helmet";
import hero from "../images/home (3).jpg";
import leftMajestic from "../images/kamaainaSunset.jpg";
import leftFireworks from "../images/kamaainaFireworks.jpg";
import LeftRight from "../components/LeftRight";
import leftWhale from "../images/leftWhale.jpg";

function Kamaaina() {
  return (
    <>
      <Helmet>
        <title>Majestic by Atlantis Cruises | Kama'aina Ticket Discounts</title>
      </Helmet>
      <div className="container mw-100">
        <div className="row position-relative heightVideo">
          <img src={hero} className="p-0 homeVidSize" alt="hero" />
          <div className="homeTextOverlayVideo fw-semibold lh-sm">
            KAMA'AINA TICKET DISCOUNTS
          </div>
        </div>
        <LeftRight
          left={leftMajestic}
          title="MAJESTIC WAIKIKI SUNSET COCKTAIL CRUISE"
          p1="Step out onto Majestic’s open-air decks and enjoy Hawaii’s beautiful sunsets and skylines. Majestic features two decks that offer the space to take in panoramic views and lovely breezes."
          p2={
            <>
              Tax and harbor fee added at checkout. Transportation not included.
              <br />
              *A valid Hawaii ID or Military ID is required at check-in*
            </>
          }
          link="https://book.majestichawaii.com/details/309586/waikiki-sunset-cocktail-cruise-kamaaina-discount"
          changeBG
          button
          kamaaina
          adult="59.25"
          child="29.63"
          saveAdult="19.75"
          saveChild="9.87"
          free
          thingsReal
        />
        <LeftRight
          left={leftWhale}
          title="MAJESTIC WHALE WATCH CRUISE"
          p1="Each year, humpback whales return to the islands from frigid Alaska to breed, calve, and nurse their young. Come witness this amazing event for yourself on board the Majestic!"
          p2={
            <>
              Tax and harbor fee added at checkout. Transportation not included.
              <br />
              *A valid Hawaii ID or Military ID is required at check-in*
            </>
          }
          link="https://book.majestichawaii.com/details/221534/whale-watch-cruise-in-waikiki-kamaaina-discount"
          button
          kamaaina
          adult="32.00"
          child="16.00"
          saveAdult="47.00"
          saveChild="23.50"
          free
          thingsReal
        />
        <LeftRight
          left={leftFireworks}
          title="MAJESTIC FRIDAY FIREWORKS & COCKTAIL CRUISE"
          p1="Experience an unforgettable evening with Friday night fireworks and cocktails on the sea aboard Majestic by Atlantis Cruises."
          p2={
            <>
              Tax and harbor fee added at checkout. Transportation not included.
              <br />
              *A valid Hawaii ID or Military ID is required at check-in*
            </>
          }
          link="https://book.majestichawaii.com/details/317222/friday-fireworks-cocktail-cruise-kamaaina-discount"
          changeBG
          button
          kamaaina
          adult="59.25"
          child="29.63"
          saveAdult="19.75"
          saveChild="9.87"
          free
          thingsReal
        />
      </div>
    </>
  );
}

export default Kamaaina;
