import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import Header from "./components/Header";
import Home from "./pages/Home";
import Footer from "./components/Footer";
import Fireworks from "./pages/Fireworks";
import Whale from "./pages/Whale";
import Charters from "./pages/Charters";
import Error from "./pages/Error";
import Parking from "./pages/Parking";
import Kamaaina from "./pages/Kamaaina";

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/friday-fireworks-cocktail-cruise"
          element={<Fireworks />}
        />
        <Route path="/whalewatch" element={<Whale />} />
        <Route path="/boat-charters" element={<Charters />} />
        <Route path="/parking" element={<Parking />} />
        <Route path="*" element={<Error />} />
        <Route path="/kamaaina" element={<Kamaaina />} />
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
