import { Link } from "react-router-dom";
import { Button } from "react-bootstrap";

export default function LeftRight({
  left,
  title,
  p1,
  p2,
  changeBG,
  button,
  link,
  kamaaina,
  adult,
  child,
  free,
  saveAdult,
  saveChild,
  thingsReal,
}) {
  function handleClick() {
    alert(
      "Please bring Hawaii driver's license, Hawaii state ID, or Military ID to check-in. Mahalo!"
    );
  }
  return (
    <div className="row">
      <div className="col-12 col-xl-6 p-0">
        <img src={left} alt="left" className="w-100" />
      </div>
      <div
        className={`col-12 col-xl-6 ${
          changeBG ? `islandRightWhite` : `islandRight`
        } d-flex justify-content-center align-items-center`}
      >
        <div className="leftRightText px-2 py-5 py-xl-0 islandRightTextBox">
          <p className={`fs-2 fw-bold ${thingsReal ? "thingsReal lh-sm" : ""}`}>
            {title}
          </p>
          <p
            className={`fs-5 mt-3 mb-0 ${
              thingsReal ? "thingsReal smallText" : ""
            }`}
          >
            {p1}
          </p>

          {kamaaina && (
            <p className="d-flex flex-row my-4 lh-1 thingsReal">
              <span className="me-4">
                Adult rate
                <br />
                <span className="fs-3 fw-semibold">${adult}</span>
                <br />
                <span style={{ color: "#EC8134" }}>Save ${saveAdult}</span>
              </span>
              <span className="me-4">
                Child rate
                <br />
                <span className="fs-3 fw-semibold">${child}</span>
                <br />
                <span style={{ color: "#EC8134" }}>Save ${saveChild}</span>
              </span>
              {free && (
                <span>
                  Kids 2 and under
                  <br />
                  <span className="fs-3 fw-semibold">FREE!</span>
                </span>
              )}
            </p>
          )}

          {p2 && (
            <p
              className={`fs-5 mt-3 mb-0 ${
                thingsReal ? "thingsReal smallText" : ""
              }`}
            >
              {p2}
            </p>
          )}

          {button && (
            <Button
              {...(kamaaina && { onClick: handleClick })}
              as={Link}
              to={link}
              className={`booknow-cta text-nowrap mt-4 mt-md-5 ${
                thingsReal ? "thingsReal" : ""
              }`}
              style={{ fontSize: "1.3rem" }}
            >
              BOOK NOW
            </Button>
          )}
        </div>
      </div>
    </div>
  );
}
